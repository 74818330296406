import { sendRequestAccessFx, shareDataStore } from 'stores/drafts/model';
import { Column, Row } from 'components/wrappers/grid/FlexWrapper';
import { InputTitle } from 'ui-kit/components/typography/Span';
import { useEffect, useState } from 'react';
import { DOCUMENTS_LINK, USER_PREVIEW } from 'constants/routes';
import { Button } from 'ui-kit/components/buttons/Button';
import { shareModalStore } from 'stores/modals/model';
import { Modal } from 'ui-kit/components/Modal';
import { useUnit } from 'effector-react';
import { type FC } from 'types/react';
import { getAccessToken } from 'utils/get-access-token';

import { Text } from './styles';
import { useNavigate } from 'react-router-dom';
import { createContractFromPdf } from 'stores/pdf/createContractFromPdf ';
import { doc } from 'prettier';

export const ShareModal: FC = () => {
    const { closeModal, modal } = shareModalStore;
    const [loading, setLoading] = useState(false);
    const [draftId, setDraftId] = useState<string | null>(null); // Store draft ID
    const { documentName, comment, emails, files } = useUnit(shareDataStore);
    const [name, setName] = useState('');
    const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
    const navigate = useNavigate();

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validateEmails = (emails: string[]) => {
        if (emails && emails.length > 0) {
            const allValid = emails.every(email => validateEmail(email));
            setIsEmailValid(allValid);
        } else {
            setIsEmailValid(false);
        }
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const draft = queryParams.get('draft');
        if (draft) {
            setDraftId(draft);
        }
        // Validate emails when modal is opened
        validateEmails(emails);
    }, [emails]);

    const handleShareDocument = async () => {
        if (!draftId) {
            console.error('Draft ID not found in the URL');
            return;
        }
        setLoading(true);

        try {
            const formData = new FormData();
            formData.append('draft', draftId);
            formData.append('comment', comment || '');
            formData.append('documentName', documentName || '');

            if (Array.isArray(emails)) {
                emails.forEach(email => formData.append('email[]', email));
            } else {
                formData.append('email', emails);
            }

            // // Append files
            if (Array.isArray(files)) {
                files.forEach(file => formData.append('files', file));
            } else {
                formData.append('files', files);
            }
            formData.forEach((value, key) => {
                console.log(key, value);
            });
            const link = USER_PREVIEW;
            formData.append('link', link);
            await sendRequestAccessFx({ formData: formData, id: draftId });
            const contractResult = await createContractFromPdf({
                nameOfPdf: documentName || 'Contract', // You can change this to any name you prefer
                pagesIds: [] // Leave empty or handle this if the logic internally handles the page selection
            });

            if (!contractResult) {
                console.error('Error creating contract');
                return;
            }

            const { pdf, id } = contractResult;

            const payload = {
                description: '',
                draft: draftId,
                massage: '',
                name: name,
                pdf: id
            };

            await fetch('https://dev-api.kiaradocs.com/documents', {
                headers: {
                    Authorization: `Bearer ${getAccessToken()}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload),
                method: 'POST'
            });

            await fetch(`https://dev-api.kiaradocs.com/user-drafts/${draftId}`, {
                headers: {
                    Authorization: `Bearer ${getAccessToken()}`
                },
                method: 'DELETE'
            });

            closeModal(); // Close modal after success
            navigate(DOCUMENTS_LINK);
        } catch (error) {
            console.error('Error sharing document:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal onClose={closeModal} width="530px" title={''} visible>
            <Column alignCenter gap="20px">
                <InputTitle style={{ fontSize: '20px', fontWeight: 500 }}>{'Send document?'}</InputTitle>
                <Text style={{ fontWeight: 500 }}>Are you sure you want to send the document?</Text>
                <input
                    style={{ fontSize: '16px', padding: '10px', width: '100%' }}
                    onChange={e => setName(e.target.value)}
                    placeholder="Enter name of document"
                    value={name}
                    type="text"
                />
                <Row width="100%" justifyEnd gap="16px">
                    <Button onClick={() => closeModal()} variant="secondary">
                        No
                    </Button>
                    <Button
                        disabled={!isEmailValid || loading} // Disable if emails are invalid or button is loading
                        onClick={handleShareDocument}
                        variant="primary"
                    >
                        {loading ? 'Sharing...' : 'Yes'}
                    </Button>
                </Row>
            </Column>
        </Modal>
    );
};
