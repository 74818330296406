import type { VariationItems, Fields } from 'types/builder/interface';
import type { FC } from 'types/react';

import { setDescriptionEditorStateEvent, $descriptionEditorState } from 'stores/builder/descriptionEditor/model';
import { hideOrShowEvents, hideOrShowStores } from 'stores/builder/hideOrShowItems';
import { RichTextEditor } from 'components/builder/RichTextEditor/RichTextEditor';
import { addClauseVariationsEvent } from 'stores/builder/block/model';
import { variationsFieldModalStore } from 'stores/modals/model';
import { Button } from 'ui-kit/components/buttons/Button';
import { FieldTypesEnum } from 'constants/builder/enums';
import { useEffect, useState, useMemo } from 'react';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';
import { Modal } from 'ui-kit/components/Modal';
import { useUnit } from 'effector-react';
import { v4 as generateId } from 'uuid';
import { EditorState } from 'draft-js';

import { getDefaultShowOrHideString } from './helpers/get-default-show-or-hide-string';
import { VariationsEditor } from './VariationsEditor/VariationsEditor';
import { getVariationItems } from './helpers/get-variation-items';
import { getVariationTitle } from './helpers/get-variation-title';
import { Edit } from './tabs/Edit/Edit';
import { $question } from './model';
import { Footer } from './styles';

export interface GetSelectedValueProps {
    variationsField?: Fields['variationsField'];
}

export const getSelectedValue = ({ variationsField }: GetSelectedValueProps) => {
    if (variationsField) {
        return variationsField[0][0].type;
    }

    return FieldTypesEnum.Radio;
};

export const VariationsFieldModal: FC = () => {
    const { closeModal, modal } = variationsFieldModalStore;
    const [
        [_, { variationsField, blockTitle, clauseId, blockId, clause }],
        descriptionEditorState,
        showOrHide,
        multiSelectValues,
        question
    ] = useUnit([
        modal,
        $descriptionEditorState,
        hideOrShowStores.showOrHide,
        hideOrShowStores.multiSelectValues,
        $question
    ]);

    const [activeTab, setActiveTab] = useState('edit');
    const [variationItems, setVariationItems] = useState<VariationItems[]>(() => getVariationItems(variationsField));
    const [selectedValue, setSelectedValue] = useState<FieldTypesEnum>(() =>
        getSelectedValue({
            variationsField
        })
    );

    const canGoToLogicTab = useMemo(
        () => question && variationItems.every(item => item.text),
        [question, variationItems]
    );

    const onTabChange = (tab: string) => {
        setActiveTab(tab);
    };

    const handleCloseModal = () => {
        const { resetMultiSelectValues, resetShowOrHide } = hideOrShowEvents;

        resetMultiSelectValues();
        resetShowOrHide();
        closeModal();
    };

    const handleSave = () => {
        const descriptionHtml = stateToHTML(descriptionEditorState.getCurrentContent());
        const plainText = descriptionEditorState.getCurrentContent().getPlainText();
        const description = plainText.trim() ? descriptionHtml : '';

        const variationItemsPayload = variationItems.map((item, index) => {
            const multiSelectedValue = multiSelectValues[index].value;

            const showOrHideString = getDefaultShowOrHideString(showOrHide[index].value);

            if (multiSelectedValue.length > 0) {
                let str = '';
                multiSelectedValue.forEach(({ value }, index) => {
                    str += value;
                    if (index >= 0 && index < multiSelectedValue.length - 1) {
                        str += ',';
                    }
                });

                str += `-${showOrHideString.value}`;

                return {
                    value: {
                        type: FieldTypesEnum.Text,
                        value: str
                    },
                    isDefault: false,
                    text: item.text
                };
            }

            return {
                value: {
                    value: showOrHide[index]?.value,
                    type: 'Text'
                },
                isDefault: false,
                text: item.text
            };
        });

        if (blockId && clauseId) {
            addClauseVariationsEvent({
                data: [
                    [
                        {
                            value: variationItemsPayload,
                            type: selectedValue,
                            title: question,
                            name: 'radio',
                            description,
                            blockId,
                            id: ''
                        }
                    ]
                ],
                blockTitle: blockTitle || '',
                clauseId,
                blockId
            });
        }

        handleCloseModal();
    };

    const handleOnChangeAnswers = ({ type = 'add', index }: { index: number; type: string }) => {
        if (type === 'remove') {
            return setVariationItems(prevState => {
                const copyOfState = [...prevState];
                copyOfState.splice(index, 1);
                return [...copyOfState];
            });
        }

        return setVariationItems(prevState => {
            const copyOfState = [...prevState];
            copyOfState.splice(index + 1, 0, {
                value: {
                    value: '',
                    type: ''
                },
                id: generateId(),
                isDefault: true,
                text: ''
            });

            return [...copyOfState];
        });
    };

    const handleSelectChange = (value: string) => {
        if ((value === FieldTypesEnum.Text || value === FieldTypesEnum.Date) && activeTab === 'logic') {
            setActiveTab('edit');
        }
        setSelectedValue(value as FieldTypesEnum);
    };

    const onEditorStateChange = (editorState: EditorState) => {
        setDescriptionEditorStateEvent(editorState);
    };

    // useEffect(() => {
    //     const contentState = stateFromHTML(variationsField ? variationsField[0][0].description ?? '' : '');

    //     onEditorStateChange(EditorState.createWithContent(contentState));
    // }, [variationsField]);
    useEffect(() => {
        const contentState = stateFromHTML(variationsField ? variationsField[0][0].description ?? '' : '');

        onEditorStateChange(EditorState.createWithContent(contentState));
    }, [variationsField]);

    return (
        <Modal
            customFooter={
                <Footer>
                    {activeTab === 'logic' && (
                        <Button
                            onClick={() => {
                                onTabChange('edit');
                            }}
                            variant="secondary"
                        >
                            Back
                        </Button>
                    )}
                    {activeTab !== 'logic' ? (
                        <Button
                            onClick={() => {
                                onTabChange('logic');
                            }}
                            disabled={!canGoToLogicTab}
                            variant="primary"
                        >
                            Next
                        </Button>
                    ) : (
                        <Button onClick={handleSave} variant="primary">
                            Save
                        </Button>
                    )}
                </Footer>
            }
            onClose={handleCloseModal}
            title="Logic"
            width="720px"
            visible
        >
            <Edit
                variationTitle={getVariationTitle(variationsField)}
                isDisabledEditInput={activeTab === 'logic'}
                handleSelectChange={handleSelectChange}
                selectedValue={selectedValue}
            />
            <VariationsEditor
                handleOnChangeAnswers={handleOnChangeAnswers}
                isShownLogicTab={activeTab === 'logic'}
                setVariationItems={setVariationItems}
                variationItems={variationItems}
                isDisabledEditInput={false}
                blockId={blockId}
                clause={clause}
            />
            <RichTextEditor
                placeholder="Enter an optional explanation..."
                onEditorStateChange={onEditorStateChange}
                editorState={descriptionEditorState}
                canAddField={false}
                forDescription
            />
        </Modal>
    );
};
